@import '../../node_modules/normalize.css/normalize.css';
@import '../../node_modules/react-rangeslider/umd/rangeslider.min.css';

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* position: relative; */
  /* top: 0; */
  /* left: 0; */
  /* right: 0; */
  /* bottom: 0; */
  /* overflow: hidden; */
  /* position: fixed; */
  /* width: 100vw; */
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  height: 100vh;
}

#root {
  z-index: 1;
  height: 100%;
}

/* Inputs */
#root button,
#root textarea,
#root input {
  outline: none; /* Prevents blue highlight around element */
}

/* Lists */
#root ul,
#root li,
#root ol {
  list-style-type: none; /* Prevents default bullet on list item */
}

/* removes the X to clear inputs on IE */
#root input[type='text']::-ms-clear {
  font-size: '12px';
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

h6 {
  /* This header is used on sub section forms like on the <GeneralSettingsEditEditDialog /> */
  margin-bottom: 8px;
}

a {
  text-decoration: none;
  color: #212121;
}

.print-visible {
  display: none;
}

@media print {
  * {
    transition: none !important;
    overflow: visible !important;
    overflow-y: visible !important;
    color: black !important;
  }

  body,
  html,
  .core-layout {
    height: auto !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }

  button {
    display: none !important;
  }

  .print-visible {
    display: block !important;
  }

  .print-hidden {
    display: none !important;
  }

  *::-webkit-scrollbar {
    width: 0 !important;
  }
  * {
    -ms-overflow-style: none;
  }
  * {
    overflow: -moz-scrollbars-none;
  }
}

@keyframes sf-orgchart-spin {
  100% {
    transform: rotate(360deg);
  }
}

/* React Range slider overrides */
.rangeslider-horizontal {
  width: 300px;
}
.rangeslider-horizontal .rangeslider__handle {
  width: 15px;
  height: 15px;
}
.rangeslider .rangeslider__handle {
  box-shadow: 1px 1px 1px #d3d3d3;
  outline: none;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #448aff;
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: none;
}

/* Intercom */
html body #intercom-container,
html body .intercom-lightweight-app {
  display: none; /* The analytics Provider will decide whether or not to show intercom if the client does not have support enabled. */
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

/* Microsoft Teams meeting panel page transition */

/* Slide left */
.msTeamsPage-slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.msTeamsPage-slide-left-enter-active {
  opacity: 1;
  transition: transform 600ms ease-in-out, opacity 800ms ease-in-out;
  transform: translate3d(0, 0, 0);
}

.msTeamsPage-slide-left-exit {
  opacity: 1;
  transform: translate3d(-100%, 0, 0);
}

.msTeamsPage-slide-left-exit-active {
  opacity: 0;
  transition: transform 600ms ease-in-out, opacity 1000ms ease-in-out;
  transform: translate3d(0, 0, 0);
}

/* Slide right */
.msTeamsPage-slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.msTeamsPage-slide-right-enter-active {
  opacity: 1;
  transition: transform 600ms ease-in-out, opacity 800ms ease-in-out;
  transform: translate3d(0, 0, 0);
}
.msTeamsPage-slide-right-exit {
  opacity: 1;
  transform: translate3d(100%, 0, 0);
}

.msTeamsPage-slide-right-exit-active {
  opacity: 0;
  transition: transform 600ms ease-in-out, opacity 1000ms ease-in-out;
  transform: translate3d(0, 0, 0);
}
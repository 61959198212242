body {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  position: fixed;
  width: 100vw;
}

@media print {
  body {
    position: initial;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    overflow: initial;
    position: initial;
    width: initial;
  }
}
